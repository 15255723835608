import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import { useNavigate } from "react-router-dom";

const useQuery = () => new URLSearchParams(useLocation().search);

export default function RecettePage(props) {
  const navigate = useNavigate();

  const [data, setData] = useState({
    id: useQuery().get("id"),
    title: useQuery().get("title"),
    image_url: null,
    steps: null,
    ingredients: null
  });
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    fetch(`/api/get-recette-details?id=${data.id}&title=${data.title}`)
      .then(res => {
        if (res.ok) {
          res.json()
            .then(d => setData(prev => {return {...prev, image_url: d.image_url, steps: d.steps, ingredients: d.ingredients}}))
        } else {  
          navigate('/recette')
        }
      })
    
    fetch("/api/check_access")
      .then(res => {
        if (res.ok)
          setAdmin(true);
      })
  }, [data.id, data.title, props.history])


  if (data.image_url) {
    return <>
      <h2 id="h2_recette">{data.title}</h2>
    
      
      <div id="write-recette">
        <img src={data.image_url} alt="recette illustration" style={{marginTop: 15}}/>

        <h3>Ingrédients :</h3>
        <ul id="ingredient_list">
          {data.ingredients && data.ingredients.map(i => {
            return <li key={i.id}>- {i.content}</li>
          })}
        </ul>


        <h3>Réalisation :</h3>
        {data.steps && data.steps.map((step, index) => {
          return <div className="recette_step" key={step.id}>
            <h4>Étape {index + 1}</h4>
            <p>{step.content}</p>
          </div>
        })}


        {admin && <div id="add-recette-button">
          <div onClick={() => navigate(`/admin/edit-recette?id=${data.id}&title=${data.title}`)}>Editer la recette</div>
        </div>}

      </div>

      <Footer />
      <Navbar color="yellow" />
    </>
  } else {
    // return loading
    return <div></div>
  }
}