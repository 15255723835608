import React, {useEffect} from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import { useNavigate } from "react-router-dom";

const TarifBox = props => {
  const navigate = useNavigate();

  return (
    <div className="tarif-box">
      {props.economies &&
      <div className="popular">
        {props.economies}
      </div>
      }
      <h3>{props.title}</h3>
      <h4>{props.price}<span className="devise">€</span></h4>
      <p>{props.subtitle !== "." ? props.subtitle : "\u00a0"}</p>
      <p>{props.duration && `Valable ${props.duration} mois`}</p>

      <div className="select-button" onClick={() => navigate(props.url)}>
        Sélectionner
      </div>

      <div className="tarif-footer">
        <p>{props.description}</p>
      </div>
    </div>
  )
}


export const TarifSuiviAlimentaire = props => {

  return (
    <>
      <div id="tarifs_suivi-alimentaire">
        <div className="overlay-tarifs">          
          <div className="tarifs-list">
            <TarifBox {...props} title="Suivi alimentaire" price="90"
              subtitle="Suivi 1 mois" duration="1" description="Suivi et plan alimentaire complet 1 mois" 
              url="/payment?formule=Suivi alimentaire&duration=1 mois"
              />
            <TarifBox {...props} title="Suivi alimentaire" price="240" 
              subtitle="Suivi 3 mois" duration="3" description="Suivi et plan alimentaire complet 3 mois" 
              url="/payment?formule=Suivi alimentaire&duration=3 mois" economies="Économisez 30€"
              />
              <TarifBox {...props} title="Suivi alimentaire" price="490" 
              subtitle="Suivi 6 mois" duration="6" description="Suivi et plan alimentaire complet 6 mois" 
              url="/payment?formule=Suivi alimentaire&duration=6 mois" economies="Économisez 60€"
              />
          </div>
        </div>
      </div>
      <Footer style={{marginTop: -59}}/>
      <Navbar color="yellow" />
    </>
  )
}


export const TarifCoachingEtSuiviMuscu = props => {

  return (
    <>
      <div id="tarifs_suivi-musculation">
        <div className="overlay-tarifs">         
          <div className="tarifs-list">
            <TarifBox {...props} title="Suivi musculation" price="90" 
              subtitle="Suivi 1 mois" duration="1" description="Suivi et plan complet musculation 1 mois" 
              url="/payment?formule=Suivi coaching&duration=1 mois"
              />
            <TarifBox {...props} title="Suivi musculation" price="240" 
              subtitle="Suivi 3 mois" duration="3" description="Suivi et plan complet musculation 3 mois" 
              url="/payment?formule=Suivi coaching&duration=3 mois"  economies="Économisez 30€"
              />

            <TarifBox {...props} title="Suivi musculation" price="490" 
              subtitle="Suivi 6 mois" duration="6" description="Suivi et plan complet musculation 6 mois" 
              url="/payment?formule=Suivi coaching&duration=6 mois"  economies="Économisez 60€"
              />
          </div>
        </div>
      </div>
      <Footer style={{marginTop: -59}}/>
      <Navbar color="yellow" />
    </>
  )
}

export const TarifCoachingAlimMuscu = props => {

  return (
    <>
      <div id="tarifs_suivi-musculation">
        <div className="overlay-tarifs">         
          <div className="tarifs-list">
            <TarifBox {...props} title="Alimentaire + Muscu" price="150" 
              subtitle="Suivi 1 mois" duration="1" description="Suivi et programme complet de 1 mois alimentaire + musculation" 
              url="/payment?formule=Alimentaire Muscu&duration=1 mois"
              />
            <TarifBox {...props} title="Alimentaire + Muscu" price="400" 
              subtitle="Suivi 3 mois" duration="3" description="Suivi et programme complet de 3 mois alimentaire + musculation" 
              url="/payment?formule=Alimentaire Muscu&duration=3 mois"  economies="Économisez 50€"
              />

            <TarifBox {...props} title="Alimentaire + Muscu" price="750" 
              subtitle="Suivi 6 mois" duration="6" description="Suivi et programme complet de 6 mois alimentaire + musculation" 
              url="/payment?formule=Alimentaire Muscu&duration=6 mois"  economies="Économisez 150€"
              />
          </div>
        </div>
      </div>
      <Footer style={{marginTop: -59}}/>
      <Navbar color="yellow" />
    </>
  )
}


export const TarifCoachingSportifIndiv = props => {

  return (
    <>
      <div id="tarifs_coaching-en-salle">
        <div className="overlay-tarifs">          
          <div className="tarifs-list">
            <TarifBox {...props} title="Séance individuelle" price="50" 
             subtitle="." description="1 séance coaching + bilan de début et fin de séance" 
             url="/payment?formule=Coaching en salle&duration=Solo"
             />
            <TarifBox {...props} title="Séance en duo" price="80" 
              subtitle="."description="1 séance de coaching en duo + bilan de début et fin de séance" 
              url="/payment?formule=Coaching en salle&duration=Duo" popular
              />
          </div>
        </div>
      </div>
      <Footer style={{marginTop: -59}}/>
      <Navbar color="yellow" />
    </>
  )
}


export const TarifCoachingSportifDomExt = props => {
  
  useEffect(() => {
    document.querySelector("nav").style.background = "#EFAB45";
    document.querySelector("nav").style.boxShadow = "0px 4px 6px 2px rgba(0, 0, 0, 0.25)";
  }, [])

  return (
    <>
      <div id="tarifs_coaching-a-domicile">
        <div className="overlay-tarifs">          
          <div className="tarifs-list">
            <TarifBox {...props} title="Séance individuelle" price="60" 
             subtitle="." description="1 séance de coaching à domicile + bilan de début et fin de séance" 
             url="/payment?formule=Coaching à domicile&duration=Solo"
             />
            <TarifBox {...props} title="Séance en duo" price="99" 
              subtitle="." description="1 séance de coaching à domicile en duo + bilan de début et fin de séance"
              url="/payment?formule=Coaching à domicile&duration=Duo" 
              />
          </div>
        </div>
      </div>
      <Footer style={{marginTop: -59}}/>
      <Navbar color="yellow" />
    </>
  )
}