import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, {useEffect, useState} from "react";


export default function PaymentBox (props) { 
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/",
      },
      redirect: "if_required"
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("Une erreur est survenue, veuillez réessayer.");
      }
    } else {
      fetch("/api/pay", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          client: props.formFields,
          order: props.orderData
        })
      })
        .then(res => {
          if (res.ok) {
            props.setIsPaid(true);
          } else {
            alert("Une erreur est survenue mais le paiement a été effectué. Veuillez me contacter par téléphone, mail ou instagram. Désolé pour ce désagrément.")
          }
        })
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [])


  return <form id="payment-form" onSubmit={handleSubmit}>
    <PaymentElement id="payment-element" />
    <button disabled={isLoading || !stripe || !elements || props.fieldsAreEmpty} id="submit">
      <span id="button-text">
        {isLoading ? <div className="spinner" id="spinner"></div> : "Payer"}
      </span>
    </button>
    {/* Show any error or success messages */}
    {message && <div id="payment-message">{message}</div>}
</form>
}