import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Homepage from "./Pages/Homepage/Homepage.js";
import PaymentPage from './Pages/Payment/PaymentPage.js';
import RecettesPage from './Pages/Recettes/RecettesPage.js';
import RecettePage from './Pages/Recettes/RecettePage.js';
import RecetteWrite, { RecetteEdit } from './Pages/Recettes/RecetteWrite.js';
import { ServiceCoachingEtSuiviMuscu, ServiceCoachingSportifDomExt, ServiceCoachingSportifIndiv, ServiceSuiviAlimentaire } from './Pages/Services/Services.js';
import { TarifCoachingAlimMuscu, TarifCoachingEtSuiviMuscu, TarifCoachingSportifDomExt, TarifCoachingSportifIndiv, TarifSuiviAlimentaire } from './Pages/Tarifs/Tarifs.js';
import LoginPage from './Pages/Authentification/LoginPage.js';
import PolitiqueConfidentialite from './Pages/Mentions/PolitiqueConfidentialite.jsx';
import MentionsLegales from './Pages/Mentions/MentionsLegales.jsx';
import NotFoundError from './Pages/Errors/NotFoundError.jsx';





function App() {

  return (
    <Router>
      <Routes>
        {/* Accueil */}
        <Route path="/" element={<Homepage />} />

        {/* Services */}
        <Route path="/services/suivi-alimentaire" element={<ServiceSuiviAlimentaire />} />
        <Route path="/services/suivi-musculation" element={<ServiceCoachingEtSuiviMuscu />} />
        <Route path="/services/coaching-en-salle" element={<ServiceCoachingSportifIndiv />} />
        <Route path="/services/coaching-a-domicile" element={<ServiceCoachingSportifDomExt />} />

        {/* Tarifs */}
        <Route path="/tarifs/suivi-alimentaire" element={<TarifSuiviAlimentaire />} />
        <Route path="/tarifs/suivi-musculation" element={<TarifCoachingEtSuiviMuscu />} />
        <Route path="/tarifs/suivi-complet" element={<TarifCoachingAlimMuscu />} />
        <Route path="/tarifs/coaching-en-salle" element={<TarifCoachingSportifIndiv />} />
        <Route path="/tarifs/coaching-a-domicile" element={<TarifCoachingSportifDomExt />} />

        {/* Paiement */}
        <Route path="/payment" element={<PaymentPage />} />

        {/* Recettes */}
        <Route path="/recette" element={<RecettesPage />} />
        <Route path="/recette/details" element={<RecettePage />} />
        <Route path="/admin/write-recette" element={<RecetteWrite />} />
        <Route path="/admin/edit-recette" element={<RecetteEdit />} />

        {/* Authentification */}
        <Route path="/admin/login" element={<LoginPage />} />

        {/* Articles */}
        <Route path="/politique-de-confidentialité" element={<PolitiqueConfidentialite />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />

        {/* Page 404 */}
        <Route path="*" element={<NotFoundError />} />
      </Routes>
    </Router>
  );
}

export default App;
