import React from "react";
import Bin from "../../Images/bin.png";
import { useNavigate } from "react-router-dom";


export default function RecetteItem(props) {
  const navigate = useNavigate();
  const handleDelete = () => {
    let confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer cette recette ?");

    if (confirmation) {
      fetch("/api/delete-recette", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({id: props.data.id})
      })
        .then(res => {
          if (res.ok) {
            alert("Supprimé.")
          }
        })
    }
  }

  return (
    <div className="recette-item" onClick={() => navigate(`/recette/details?id=${props.data.id}&title=${props.data.title}`)}>
      <img src={props.data.image_url} alt="recette illustration" />
      <div className="recette-content">
        {/* <div className="recette-categories">
          <div className="recette-category" style={{background: "cadetblue"}}>Gras</div>
          <div className="recette-category" style={{background: "chocolate"}}>Très bon</div>
          <div className="recette-category" style={{background: "green"}}>Recommandé</div>
        </div> */}
        {props.login && <img src={Bin} alt="bin" style={{width: 20, height: 20}} onClick={handleDelete}/>}
        <h3>{props.data.title}</h3>
      </div>
    </div>
  )
}