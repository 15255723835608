import React, { useState } from "react";
import kevin_logo from '../Images/kevin_coaching_logo.png';
import MenuIcon from "../Images/menu.png";
import CloseIcon from "../Images/close.png";

const Navbar = props => {
  const [serviceOpen, setServiceOpen] = useState(false);
  const [tarifsOpen, setTarifsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  // useEffect(() => {
  //   window.onscroll = () => {
  //     if (!props.color) {
  //       if (document.documentElement.scrollTop > 50) {
  //         document.querySelector("nav").style.backgroundColor = "rgba(240,240,240)";
  //         document.querySelector("nav").style.boxShadow = "0px 4px 6px 2px rgba(0, 0, 0, 0.25)";
  //       } else {
  //         if (!serviceOpen) {
  //           document.querySelector("nav").style.backgroundColor = "rgba(240,240,240,0)";
  //           document.querySelector("nav").style.boxShadow = "none";
  //         }
  //       }
  //     }
  //   }
  // }, [props.color, serviceOpen])

  const checkMenu = () => {
    if (serviceOpen) {
      setServiceOpen(false);
    }

    if (tarifsOpen) {
      setTarifsOpen(false);
    }
  }


  return <>
  <div id="" style={{position: "fixed", width: "100vw", height: "100vh", top: 0, left: 0, display: serviceOpen || tarifsOpen ? "block" : "none"}} onMouseEnter={checkMenu} />
  {menuOpen && <div id="menu-xs">
    <ul>
      <li><a href="/">Accueil</a></li>
      <p>Services</p>
      <ul>
        <li><a href="/services/suivi-alimentaire">Suivi alimentaire</a></li>
        <li><a href="/services/suivi-musculation">Suivi musculation</a></li>
        <li><a href="/services/coaching-en-salle">Coaching en salle</a></li>
        <li><a href="/services/coaching-a-domicile">Coaching à domicile</a></li>
      </ul>
      <p>Tarifs</p>
      <ul>
        <li><a href="/tarifs/suivi-alimentaire">Suivi alimentaire</a></li>
        <li><a href="/tarifs/suivi-musculation">Suivi musculation</a></li>
        <li><a href="/tarifs/suivi-complet">Suivi complet</a></li>
        <li><a href="/tarifs/coaching-en-salle">Coaching en salle</a></li>
        <li><a href="/tarifs/coaching-a-domicile">Coaching à domicile</a></li>
      </ul>
      <li><a href="/recette">Recettes</a></li>
    </ul>
  </div>}

  <nav style={{backgroundColor: props.color === "yellow" ? "#EFAB45" : "#F0F0F0"}}>
    <a href="/"><img src={kevin_logo} alt="logo kevin coaching" title="Logo Kevin Coaching" /></a>
    <ul id="lg-list">
      <li><a href="/">Accueil</a></li>
      <li onMouseOver={() => {
        setServiceOpen(true);
        setTarifsOpen(false);
      }}>
        <p>Services</p>
        <div id="service_menu" style={{display: serviceOpen ? "block" : "none"}}>
          <ul onMouseOver={() => setServiceOpen(true)}>
            <li><a href="/services/suivi-alimentaire">Suivi alimentaire</a></li>
            <li><a href="/services/suivi-musculation">Suivi musculation</a></li>
            <li><a href="/services/coaching-en-salle">Coaching en salle</a></li>
            <li><a href="/services/coaching-a-domicile">Coaching à domicile</a></li>
          </ul>
        </div>
      </li>
      <li onMouseOver={() => {
        setTarifsOpen(true);
        setServiceOpen(false);
      }}>
        <p>Tarifs</p>
        <div id="service_menu" style={{display: tarifsOpen ? "block" : "none"}}>
          <ul onMouseOver={() => setTarifsOpen(true)}>
            <li><a href="/tarifs/suivi-alimentaire">Suivi alimentaire</a></li>
            <li><a href="/tarifs/suivi-musculation">Suivi musculation</a></li>
            <li><a href="/tarifs/suivi-complet">Suivi complet</a></li>
            <li><a href="/tarifs/coaching-en-salle">Coaching en salle</a></li>
            <li><a href="/tarifs/coaching-a-domicile">Coaching à domicile</a></li>
          </ul>
        </div>
      </li>
      <li><a href="/recette">Recettes</a></li>
    </ul>

    {!menuOpen ?
      <img src={MenuIcon} alt="menu icon" id="menu-icon" onClick={() => setMenuOpen(true)}/>
    :
      <img src={CloseIcon} alt="close icon" id="menu-icon" onClick={() => setMenuOpen(false)}/>
    }
  </nav>
  </>
}


export default Navbar;