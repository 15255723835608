import React, {useEffect} from "react"; 
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";


import SuiviAlimentaireIcon from "../../Images/suivi_alimentaire.png";
import CoachingEtSuiviMuscuIcon from "../../Images/coaching_et_suivi_muscu.png";
import CoachingSportifDomExtIcon from "../../Images/coaching_sportif_dom_ext.png";
import CoachingSportifIndivIcon from "../../Images/coaching_sportif_individuel.png";


import BilanCompletIcon from "../../Images/Services/Suivi alimentaire/bilan_complet.png";
import BilanHebdoIcon from "../../Images/Services/Suivi alimentaire/bilan_hebdomadaire.png";
import ConseilIcon from "../../Images/Services/Suivi alimentaire/conseils.png";
import AllDaysIcon from "../../Images/Services/Suivi alimentaire/24-7.png";
import BilanFinIcon from "../../Images/Services/Suivi alimentaire/bilan_de_fin.png";
import RulesIcon from "../../Images/Services/rules.png";
import EarIcon from "../../Images/Services/ear.png";
import ListIcon from "../../Images/Services/list.png";

import { useNavigate } from "react-router-dom";

export const ServiceSuiviAlimentaire = props => {
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelector("nav").style.background = "#EFAB45";
    document.querySelector("nav").style.boxShadow = "0px 4px 6px 2px rgba(0, 0, 0, 0.25)";
  }, [])

  return (
    <>
      <div id="services_suivi-alimentaire">
        <div className="overlay">
          <div className="main-content">
            <div className="main-content-header">
              <img src={SuiviAlimentaireIcon} alt="suivi alimentaire" className="service-icon" />
              <h2>Un suivi alimentaire complet c'est :</h2>
            </div>


            <div className="main-content-body">

              <div className="main-content-body__row">
                <div>
                  <img src={BilanCompletIcon} alt="bilan complet icon"/>
                  <h3>Un bilan complet dès le début du suivi</h3>
                </div>

                <div>
                  <div className="yellow-divider disable-on-lg" />
                  <img src={SuiviAlimentaireIcon} alt="suivi alimentaire icon"/>
                  <h3>Un programme alimentaire adapté à vos besoins</h3>
                </div>
              </div>

              <div className="main-content-body__row">
                <div>
                  <div className="yellow-divider" />
                  <img src={BilanHebdoIcon} alt="bilan hebdomadaire icon"/>
                  <h3>Un bilan hebdomadaire avec ajustement du programme</h3>
                </div>

                <div>
                  <div className="yellow-divider" />
                  <img src={ConseilIcon} alt="conseils icon"/>
                  <h3>De nombreux conseils tout au long du suivi</h3>
                </div>
              </div>

              <div className="main-content-body__row">
                <div>
                  <div className="yellow-divider" />
                  <img src={AllDaysIcon} alt="dispo 24-7 icon"/>
                  <h3>Une disponibilité 7J/7 pour répondre à vos questions</h3>
                </div>

                <div>
                  <div className="yellow-divider" />
                  <img src={BilanFinIcon} alt="bilan fin icon"/>
                  <h3>Un bilan complet de fin du suivi avec comparatif des mensurations/photos</h3>
                </div>
              </div>

            </div>

            <div className="tarif-button" onClick={() => navigate("/tarifs/suivi-alimentaire")}>
              Voir les tarifs
            </div>
          </div>
        </div>
      </div>
      <Footer style={{marginTop: -59}}/>
      <Navbar color="yellow" />
    </>
  )
}


export const ServiceCoachingEtSuiviMuscu = props => {
  const navigate = useNavigate();
  useEffect(() => {
    document.querySelector("nav").style.background = "#EFAB45";
    document.querySelector("nav").style.boxShadow = "0px 4px 6px 2px rgba(0, 0, 0, 0.25)";
  }, [])

  return (
    <>
      <div id="services_suivi-musculation">
        <div className="overlay">
          <div className="main-content">
            <div className="main-content-header">
              <img src={CoachingEtSuiviMuscuIcon} alt="suivi alimentaire" className="service-icon" />
              <h2>Un suivi musculation complet c'est :</h2>
            </div>


            <div className="main-content-body">

              <div className="main-content-body__row">
                <div>
                  <img src={BilanCompletIcon} alt="bilan complet icon"/>
                  <h3>Un bilan complet dès le début du suivi</h3>
                </div>

                <div>
                  <div className="yellow-divider disable-on-lg" />
                  <img src={CoachingEtSuiviMuscuIcon} alt="suivi alimentaire icon"/>
                  <h3>Un programme musculation adapté à vos besoins et niveaux</h3>
                </div>
              </div>

              <div className="main-content-body__row">
                <div>
                  <div className="yellow-divider" />
                  <img src={BilanHebdoIcon} alt="bilan hebdomadaire icon"/>
                  <h3>Un bilan hebdomadaire avec ajustement du programme</h3>
                </div>

                <div>
                  <div className="yellow-divider" />
                  <img src={RulesIcon} alt="consignes icon" />
                  <h3>Un bilan hebdomadaire avec ajustement du programme</h3>
                </div>

              </div>

              <div className="main-content-body__row">
                <div>
                  <div className="yellow-divider" />
                  <img src={ConseilIcon} alt="conseils icon"/>
                  <h3>De nombreux conseils tout au long du suivi</h3>
                </div>

                <div>
                  <div className="yellow-divider" />
                  <img src={AllDaysIcon} alt="dispo 24-7 icon"/>
                  <h3>Une disponibilité 7J/7 pour répondre à vos questions</h3>
                </div>

              </div>

              <div className="main-content-body__row">
                <div>
                  <div className="yellow-divider" />
                  <img src={BilanFinIcon} alt="bilan fin icon"/>
                  <h3>Un bilan complet de fin du suivi avec comparatif des mensurations / photos / charge de travail</h3>
                </div>
              </div>

            </div>

            <div className="tarif-button" onClick={() => navigate("/tarifs/suivi-musculation")}>
              Voir les tarifs
            </div>
          </div>
        </div>
      </div>
      <Footer style={{marginTop: -59}}/>
      <Navbar color="yellow" />
    </>
  )
}


export const ServiceCoachingSportifIndiv = props => {
  const navigate = useNavigate();
  useEffect(() => {
    document.querySelector("nav").style.background = "#EFAB45";
    document.querySelector("nav").style.boxShadow = "0px 4px 6px 2px rgba(0, 0, 0, 0.25)";
  }, [])

  return (
    <>
      <div id="services_coaching-en-salle">
        <div className="overlay">
          <div className="main-content">
            <div className="main-content-header">
              <img src={CoachingSportifIndivIcon} alt="suivi alimentaire" className="service-icon" />
              <h2>Un coaching en salle de qualité c'est :</h2>
            </div>


            <div className="main-content-body">

              <div className="main-content-body__row">
                <div>
                  <img src={BilanCompletIcon} alt="entretien complet icon"/>
                  <h3>Un entretien complet avant la première séance</h3>
                </div>

                <div>
                  <div className="yellow-divider disable-on-lg" />
                  <img src={CoachingEtSuiviMuscuIcon} alt="suivi muscu icon"/>
                  <h3>Une séance créée en fonction de vos objectifs et niveau</h3>
                </div>
              </div>

              <div className="main-content-body__row">
                <div>
                  <div className="yellow-divider" />
                  <img src={CoachingSportifIndivIcon} alt="bilan hebdomadaire icon"/>
                  <h3>L'accès à du matériel et machine de professionnel</h3>
                </div>

                <div>
                  <div className="yellow-divider" />
                  <img src={ConseilIcon} alt="conseils icon"/>
                  <h3>Une démonstration et explication de chaque exercice</h3>
                </div>
              </div>

              <div className="main-content-body__row">
                <div>
                  <div className="yellow-divider" />
                  <img src={RulesIcon} alt="dispo 24-7 icon"/>
                  <h3>Correction et adaptation des exercices au besoin</h3>
                </div>

                <div>
                  <div className="yellow-divider" />
                  <img src={EarIcon} alt="bilan fin icon"/>
                  <h3>Une écoute de vos besoins et réponse à vos questions</h3>
                </div>
              </div>

              <div className="main-content-body__row">
                <div>
                  <div className="yellow-divider" />
                  <img src={BilanFinIcon} alt="dispo 24-7 icon"/>
                  <h3>Un bilan de fin de séance</h3>
                </div>
              </div>

            </div>

            <div className="tarif-button" onClick={() => navigate("/tarifs/coaching-en-salle")}>
              Voir les tarifs
            </div>
          </div>
        </div>
      </div>
      <Footer style={{marginTop: -59}}/>
      <Navbar color="yellow" />
    </>
  )
}


export const ServiceCoachingSportifDomExt = props => {
  const navigate = useNavigate();
  useEffect(() => {
    document.querySelector("nav").style.background = "#EFAB45";
  }, [])

  return (
    <>
      <div id="services_coaching-a-domicile">
        <div className="overlay">
          <div className="main-content">
            <div className="main-content-header">
              <img src={CoachingSportifDomExtIcon} alt="suivi alimentaire" className="service-icon" />
              <h2>Un coaching à domicile de qualité c'est :</h2>
            </div>


            <div className="main-content-body">

              <div className="main-content-body__row">
                <div>
                  <img src={BilanCompletIcon} alt="bilan complet icon"/>
                  <h3>Un entretien complet avant la première séance</h3>
                </div>

                <div>
                  <div className="yellow-divider disable-on-lg" />
                  <img src={ListIcon} alt="suivi alimentaire icon"/>
                  <h3>Une séance créée en fonction de votre niveau et matériel </h3>
                </div>
              </div>

              <div className="main-content-body__row">
                <div>
                  <div className="yellow-divider" />
                  <img src={CoachingSportifIndivIcon} alt="bilan hebdomadaire icon"/>
                  <h3>L'accès à du matériel professionnel apporté par mes soins</h3>
                </div>

                <div>
                  <div className="yellow-divider" />
                  <img src={ConseilIcon} alt="conseils icon"/>
                  <h3>Une démonstration et explication de chaque exercice</h3>
                </div>
              </div>

              <div className="main-content-body__row">
                <div>
                  <div className="yellow-divider" />
                  <img src={RulesIcon} alt="dispo 24-7 icon"/>
                  <h3>Correction et adaptation des exercices au besoin</h3>
                </div>

                <div>
                  <div className="yellow-divider" />
                  <img src={EarIcon} alt="bilan fin icon"/>
                  <h3>Une écoute de vos besoins et réponse à vos questions</h3>
                </div>
              </div>

              <div className="main-content-body__row">
                <div>
                  <div className="yellow-divider" />
                  <img src={BilanFinIcon} alt="dispo 24-7 icon"/>
                  <h3>Un bilan de fin de séance</h3>
                </div>
              </div>

            </div>

            <div className="tarif-button" onClick={() => navigate("/tarifs/coaching-a-domicile")}>
              Voir les tarifs
            </div>
          </div>
        </div>
      </div>
      <Footer style={{marginTop: -59}}/>
      <Navbar color="yellow" />
    </>
  )
}


