import React, {useEffect, useState} from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import PaymentBox from "./PaymentBox";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CheckMarkIcon from "../../Images/check.png";

const stripePromise = loadStripe("pk_live_51JZyXTHJDW1diCgl4YGt6jTmtgU2KKYrNFVqGUz4Tos8NmwO3ClqzvyqIgZicE2pG3V6wx4IwU2XdsF0S25jtSjk00TJl4Nua2")
const useQuery = () => new URLSearchParams(useLocation().search);

export default function PaymentPage (props) {
  const navigate = useNavigate();
  const query = useQuery();
  const [clientSecret, setClientSecret] = useState(null);
  const [orderData, setOrderData] = useState({
    formule: query.get("formule"),
    duration: query.get("duration")
  });
  const [backgroundId, setBackgroundId] = useState("");
  const [formFields, setFormFields] = useState({
    name: "", firstname: "", mail: "", phone: "", address: "", zipcode: null, city: ""
  })
  const [fieldsAreEmpty, setFieldsAreEmpty] = useState(true);
  const [isPaid, setIsPaid] = useState(false);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#efab45',
      colorText: '#2b2b2b',
      colorBackground: '#E2E2E2'
    },
  };

  const options = {clientSecret, appearance}
  
  useEffect(() => {
    switch (orderData.formule) {
      case "Suivi alimentaire":
        setBackgroundId("tarifs_suivi-alimentaire");
        break;
    
      case "Suivi coaching":
        setBackgroundId("tarifs_suivi-musculation");
        break;

      case "Coaching en salle":
        setBackgroundId("tarifs_coaching-en-salle");
        break;

      case "Coaching à domicile":
        setBackgroundId("tarifs_coaching-a-domicile");
        break;
      
      case "Alimentaire Muscu":
        setBackgroundId("tarifs_suivi-alimentaire");
        break;

      default:
        navigate("/");
        break;
    }
  }, [orderData.formule, props.history])

  useEffect(() => {
    if (orderData.duration && orderData.formule) {
      fetch(`/get-price?formule=${orderData.formule}&duration=${orderData.duration}`)
        .then(res => res.json())
        .then(data => {
          setOrderData(prev => {return {...prev, price: data.price}})
          // Create PaymentIntent as soon as the page loads
          fetch("/create-payment-intent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({price: (data.price * 100).toFixed(0)}),
          })
            .then((res) => res.json())
            .then((data) => {
              setClientSecret(data.clientSecret);
            });
        })
    }
    
  }, [orderData.duration, orderData.formule]);

  useEffect(() => {
    if ( formFields.name
      && formFields.firstname
      && formFields.mail
      && formFields.phone
      && formFields.address
      && formFields.zipcode
      && formFields.city
    ) {
      setFieldsAreEmpty(false);
    } else {
      setFieldsAreEmpty(true);
    }
  }, [formFields])

  return (
    <>
      <div id={backgroundId}>
        <div className="overlay-payment">         
          {orderData.price && <div className="main-content">
            {!isPaid && <>
              <h2>Paiement</h2>
              <div>
                <div id="contact_infos">
                  <input type="text" name="last_name" id="name" placeholder='Nom' value={formFields.name} onChange={e => setFormFields(prev => {return {...prev, name: e.target.value.toUpperCase()}})}/>
                  <input type="text" name="firstname" id="firstname" placeholder='Prénom' value={formFields.firstname} onChange={e => setFormFields(prev => {return {...prev, firstname: e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase()}})}/>
                  <input type="email" name="email" id="email" placeholder='Adresse mail' value={formFields.mail} onChange={e => setFormFields(prev => {return {...prev, mail: e.target.value.toLowerCase()}})}/>
                  <input type="phone" name="phone" id="phone-number" placeholder='Numéro de tél' value={formFields.phone} onChange={e => setFormFields(prev => {return {...prev, phone: e.target.value}})}/>
                  <input type="address" name="address" id="address" placeholder='Adresse' value={formFields.address} onChange={e => setFormFields(prev => {return {...prev, address: e.target.value}})}/>
                  <input type="number" name="zipcode" id="zipcode" placeholder='Code Postal' value={formFields.zipcode} onChange={e => setFormFields(prev => {return {...prev, zipcode: e.target.value}})}/>
                  <input type="text" name="city" id="city" placeholder='Ville' value={formFields.city} onChange={e => setFormFields(prev => {return {...prev, city: e.target.value.toUpperCase()}})}/>
                </div>

                <div id="recap-achat">
                  <h3>Résumé de la commande</h3>
                  <p>Formule <span>{orderData && orderData.formule}</span></p>
                  <p>Durée <span>{orderData && orderData.duration}</span></p>
                  <div id="recap-divider" />
                  <p id="total">Total<span>{orderData.price} €</span></p>
                </div>
              </div>

              <div id="recap-divider" style={{marginBottom: 25, width: "60%"}} />
              {clientSecret && <Elements stripe={stripePromise} options={options}>
                <PaymentBox fieldsAreEmpty={fieldsAreEmpty} formFields={formFields} setIsPaid={setIsPaid} orderData={orderData} />
              </Elements>}
            </>
            }

            {isPaid && <div id="paid">
              <img src={CheckMarkIcon} alt="check icon" style={{width: 150}}/>
              <h2>Paiement accepté</h2>
              <p>Merci de me faire confiance !<br />Vous allez être contacté dans les plus brefs délais.</p>
            </div>            
            }

          </div>}

        </div>
      </div>


      <Footer style={{marginTop: -59}} />
      <Navbar color="yellow" />
    </>
  )
}