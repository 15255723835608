import React from "react";


const Footer = props => {

  return <footer style={props.style}>
    <p><a href="/politique-de-confidentialité">Politique de confidentialité</a></p>
    <p>© Kevin Coaching | Tous droits réservés | 2022</p>
    <p><a href="/mentions-legales">Mentions légales</a></p>
  </footer>
}


export default Footer;