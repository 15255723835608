import React from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";



export default function MentionsLegales(props) {

  return <>
    <div id="article">
      <h1>Mentions légales</h1>
      <br/>
      www.kevincoaching.fr est conçu et développé par :  <br/>
      Gaëtan VETILLARD<br/>
      gaetan.vetillard.dev@gmail.com<br/>
      53000 Laval <br/>
      <br/>
      Pour :<br/>
      Kevin LE BLANC<br/>
      53000 Laval <br/><br/>


      
      <strong>Copyright et Informations personnelles  </strong><br/><br/>
      Conformément à la Loi « Informatique et Libertés » n°78-17 du 6 janvier 1978,  
      les informations vous concernant sont destinées à l’éditeur de ce site internet,  
      responsable du traitement. Vous disposez d’un droit d’accès, de rectification  
      et de suppression des données qui vous concernent. Vous pouvez l’exercer en contactant  
      l’éditeur de ce site internet. En cas de rectification, une copie des informations telles  
      que rectifiées sera communiquée à l’intéressé par voie électronique ou par courrier.  
      Les informations fournies par chaque panéliste seront exploitées de manière anonyme  
      et non nominatives à des fins d’analyse et de statistique.  <br/><br/>

      <strong>Mentions de droit réservé  </strong><br/><br/>
      En vous connectant à ce site internet, vous accédez à un contenu protégé par la loi,  
      notamment par les dispositions du code de la propriété intellectuelle. L’éditeur n’autorise  
      qu’un usage strictement personnel des données, informations ou contenus auxquels vous accédez,  
      limité à un enregistrement temporaire sur votre ordinateur aux fins d’affichages sur un seul écran  
      ainsi que la reproduction, en un unique exemplaire, pour copie de rubrique, garde ou impression  
      sur papier. Toute autre utilisation est soumise à notre autorisation expresse préalable.  
      En poursuivant votre visite de notre site vous acceptez de respecter les restrictions ci-dessus.  
      Droits de reproduction et de diffusion réservés.  <br/><br/>

      <strong>Exploitation des données </strong><br/><br/>
      Les données collectées grâce aux différents formulaires de ce site internet sont destinées  
      exclusivement à favoriser la communication entre l’éditeur et les visiteurs. Toute information  
      qui pourrait être transmise à un quelconque tiers-hormis les organismes des ministères de l’Etat  
      Français qui sont habilités à les demander-le serait contre la volonté de l’éditeur.  <br/><br/>

      <strong>Crédits photos </strong><br/><br/>
      Kevin Le Blanc<br/><br/>

      <strong>Utilisation du courrier électronique  </strong><br/><br/>
      Si vous souhaitez utiliser le courrier électronique mis à votre disposition sur ce site,  
      vous êtes informé(e) que le secret des correspondances transmises sur le réseau internet  
      n’est pas garanti.
    </div>

    <Footer />
    <Navbar color="yellow" />
  </>
}