import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import { useNavigate } from 'react-router-dom';

import "./LoginPage.css";


export default function LoginPage(props) {
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState({username: "", password: ""})

  const handleSubmit = () => {
    if (formFields.username && formFields.password) {
      fetch(`/api/login`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(formFields)
      })
        .then(res => {
          if (res.ok) {
            navigate('/')
          } else {
            alert("Error")
          }
        })
    }
  }

  return <>
    <div id="login-main-content">
      <input type="text" placeholder="Username" className="input-login" style={{marginTop: -59}} onChange={e => setFormFields(prev => {return {...prev, username: e.target.value}})}/>
      <input type="password" placeholder="Mot de passe" className="input-login" onChange={e => setFormFields(prev => {return {...prev, password: e.target.value}})}/>
      <button type="submit" id="login-button" onClick={handleSubmit}>Connexion</button>
    </div>

    <Footer style={{marginTop: -59}}/>
    <Navbar color="yellow"/>
  </>
}