import React, { useEffect } from "react";
import Footer from "../../Components/Footer"
import Navbar from "../../Components/Navbar"
import { useNavigate } from "react-router-dom"

import NotFound from "../../Images/page-not-found.png";

export default function NotFoundError(props) {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/")
    }, 5000)
  }, [props.history])

  return <>
    <div id="error">
      <img src={NotFound} alt="illustration not found"/>
      <h1>Cette page n'existe pas !</h1>
      <h2>Vous allez être redirigé...</h2>
    </div>
    

    <Footer style={{marginTop: -59}} />
    <Navbar color="yellow" />
  </>
}