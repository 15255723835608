import React, { useEffect, useState } from "react";

import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";

import "./recettes.css";
import RecetteItem from "./RecetteItem";


export default function RecettesPage (props) {
  const [login, setLogin] = useState(false);
  const [recettes, setRecettes] = useState([]);

  useEffect(() => {
    fetch("/api/check_access")
      .then (res => {
        if (res.ok) {
          setLogin(true);
        }
      })
    fetch("/api/get-recettes")
      .then(res => res.json())
      .then(data => setRecettes(data))
  }, [])


  return (
    <>
      <h2 id="h2_recette">Mes idées recettes</h2>

      <div id="recettes_list">
        {recettes.length > 0 ? recettes.map(r => {
          return <RecetteItem {...props} key={r.id} data={r} login={login} />
        })
        :
        "Aucune recette n'a été trouvée"
        }
      </div>

      <Footer />
      <Navbar color="yellow" />
    </>
  )
}