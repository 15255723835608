import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Illustration from "../../Images/homepage_illustration.png";
import Navbar from '../../Components/Navbar';
import Footer from "../../Components/Footer";

import PresentationMainImage from '../../Images/presentation_main.jpg';

// Images de présentation compétition
import Debut from "../../Images/debut.jpg";
import Competition_1 from "../../Images/competition_1.jpg";
import Competition_2 from "../../Images/competition_2.jpg";
import Competition_3 from "../../Images/competition_3.jpg";

// Icones
import SuiviAlimentaire from "../../Images/suivi_alimentaire.png";
import CoachingEtSuiviMuscu from "../../Images/coaching_et_suivi_muscu.png";
import CoachingSportifDomExt from "../../Images/coaching_sportif_dom_ext.png";
import CoachingSportifIndiv from "../../Images/coaching_sportif_individuel.png";
import MoreIcon from "../../Images/more.png";
import Instagram from "../../Images/instagram.png";
import Telephone from "../../Images/telephone.png";

// Avis
import StarIcon from "../../Images/star.png";
import StarYellowIcon from "../../Images/star_yellow.png";
import StarGreyIcon from "../../Images/star_grey.png";
import ProfilPicture from "../../Images/profil_picture.png";


const Homepage = props => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [reviews, setReviews] = useState({
    global_rating: null,
    total_reviews: null,
    reviews: null
  });
  const [photosCoaching, setPhotosCoaching] = useState([]);
  // const [index, setIndex] = useState(0);
  const index = 0;

  const [formFields, setFormFields] = useState({
    name: "", phone: "", mail: "", message: ""
  })


  const sendMessage = () => {
    if ( formFields.name
      && formFields.phone
      && formFields.mail
      && formFields.message ) {
        fetch('/api/send_message', {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(formFields)
        })
          .then(res => {
            if (res.ok) {
              setFormFields({name: "", phone: "", mail: "", message: ""});
              setMessage("Votre message a bien été envoyé !");
            }
          })
      }
  }

  
  useEffect(() => {
    fetch("/api/get_coaching_img")
      .then(res => res.json())
      .then(data => setPhotosCoaching(data.img));
    fetch("/api/get-reviews")
      .then(res => res.json())
      .then(data => setReviews(data));
  }, [])

  return (
  <>
    <section id="homepage">
      <div id="homepage_body">
        <div id="homepage_left">
          <h1>Prêt à devenir la meilleure version de vous même ?</h1>
          <h2>Travaillons ensemble vers l'atteinte de vos objectifs</h2>
          <div id="homepage_list">
            <div id="homepage_bar" />
            <ul>
              <li>Remise en forme</li>
              <li>Perte de poids</li>
              <li>Prise de masse</li>
              <li>Préparation compétition</li>
            </ul>
          </div>
        </div>
        <div id="homepage_right">
          <img src={Illustration} alt="musculation man illustration kevin coaching"/>
        </div>
      </div>
    </section>


    <section id="presentation">
      <div id="main_presentation">
        <img src={PresentationMainImage} alt="kevin coaching"/>
        <div>
          <h3>Coach - Préparateur physique - Athlète</h3>
          <p>
            Coach depuis 2015, athlète depuis 2016, mes connaissances en musculation, fitness et diététique accumulées depuis toutes ces années m'ont permis d'obtenir le titre de champion régional et vice champion demi finale France de culturisme en 2018.
            <br /><br />
            Coachant au total plus d'une centaine de clients depuis 2015, je mets aujourd'hui mes services à votre disposition, pour que demain VOUS puissiez atteindre VOS objectifs.
            ​<br /><br />
            Laissez vous guider par mes conseils et soyez assuré d'une prise en charge complète, quelque-soit votre objectif il sera réalisé et vous en sortirez grandi !
          </p>
        </div>
      </div>

      <div id="competitions_presentation">
        <h3>Votre seule limite, c'est vous.</h3>
        <div id="competition_list">
          <div className='competition_list_item'>
            <img src={Debut} alt="kevin coaching debut" />
            <p>Début en 2013</p>
          </div>

          <div className='competition_list_item'>
            <img src={Competition_1} alt="kevin coaching competition 2016" />
            <p>1ère compétition en 2016<br />
            "NFS Top 13"</p>
          </div>

          <div className='competition_list_item'>
            <img src={Competition_2} alt="kevin coaching competition 2018" />
            <p>
              2ème compétition 2018<br />
              FCPCN<br />
              (Champion régional/vice champion inter-régional)
            </p>
          </div>

          <div className='competition_list_item'>
            <img src={Competition_3} alt="kevin coaching competition 2021 open des corsaires" />
            <p>
              3 ème compétition 2021<br />
              Open des corsaires<br />
              (Champion men's physique -35ans)
            </p>
          </div>
        </div>
      </div>
    </section>


    <section id="services">
      <h3>Être coach personnalisé c'est :</h3>
      <h4>Vous écouter, vous comprendre, vous aider, vous encourager, vous accompagner
        jusqu'à l'atteinte de vos objectifs !
      </h4>
      <div id="services_list">
        <div className="services_list_item" onClick={() => navigate('/services/suivi-alimentaire')}>
          <img src={SuiviAlimentaire} alt="logo suivi alimentaire" />
          <div>
            <h5>Suivi alimentaire</h5>
            <p>Plan alimentaire établi en fonction de vos objectifs et besoins.</p>
          </div>
          <img src={MoreIcon} alt="logo more" />
        </div>

        <div className="services_list_item" onClick={() => navigate('/services/suivi-musculation')}>
          <img src={CoachingEtSuiviMuscu} alt="logo coaching et suivi muscu" />
          <div>
            <h5>Coaching et suivi musculation</h5>
            <p>
              Plan d'entraînement fait sur mesure en fonction de 
              vos objectifs, niveaux, matériel disponible.
            </p>
          </div>
          <img src={MoreIcon} alt="logo more" />
        </div>

        <div className="services_list_item" onClick={() => navigate('/services/coaching-en-salle')}>
          <img src={CoachingSportifIndiv} alt="logo coaching sportif individuel" />
          <div>
            <h5>Coaching sportif individuel en salle</h5>
            <p>
              Séance en salle établie en fonction de votre objectif et niveau.
            </p>
          </div>
          <img src={MoreIcon} alt="logo more" />
        </div>

        <div className="services_list_item" onClick={() => navigate('/services/coaching-a-domicile')}>
          <img src={CoachingSportifDomExt} alt="logo coaching sportif domicile et extérieur" />
          <div>
            <h5>Coaching sportif domicile/extérieur</h5>
            <p>
              Séance chez vous ou en extérieur, je m'adapte à vos objectifs ainsi que votre niveau.
            </p>
          </div>
          <img src={MoreIcon} alt="logo more" />
        </div>

      </div>
    </section>


    <section id="photos">
      {photosCoaching && photosCoaching.length > 0 && photosCoaching.map((p, i) => {
        if (i >= index && i <= index+5) {
          return <img src={`/img/presentation/${p}`} alt="coaching" key={i}/>
        } else {
          return null
        }
      })}
    </section>


    <section id="avis">
      <h3>Les avis de mes clients comptent !</h3>
      <div id="avis_totaux">
        <img src={StarIcon} alt="star" />
        <img src={StarIcon} alt="star" />
        <img src={StarIcon} alt="star" />
        <img src={StarIcon} alt="star" />
        <img src={StarIcon} alt="star" />
        <p>({reviews.total_reviews})</p>
      </div>

      <div id="avis_liste">
        {reviews.reviews && reviews.reviews.map((a, index) => {
          return (
            <div className="avis_item" key={index}>
              <img src={ProfilPicture} alt="profil" />
              <p>{a.name}</p>
              <div className="avis_item_reward">
                {a.rating > 0 && Array(a.rating).fill(1).map((i, index) => {
                  return <img src={StarYellowIcon} alt="star yellow" key={index} /> 
                })}
                {a.rating < 5 && Array(5-a.rating).fill(1).map((i, index) => {
                  return <img src={StarGreyIcon} alt="star grey" key={index} />
                })}
              </div>
              <p className='avis_date'>{a.date}</p>
              <p className='avis_content'>
              {a.text}
              </p>
            </div>
          )
        })}
      </div>

    </section>


    <section id="contact">
      <h3>Me contacter</h3>
      <div id="contact_coords">
        <div>
          <img src={Instagram} alt="instagram logo" />
          <p>@kevincoaching.fr</p>
        </div>
        <div>
          <img src={Telephone} alt="telephone logo" />
          <p>06 38 42 24 09</p>
        </div>
      </div>
      <div>
        <div id="contact_infos">
          <input type="text" name="name" id="name" placeholder='Nom Prénom' value={formFields.name} onChange={e => setFormFields(prev => {return {...prev, name: e.target.value}})}/>
          <input type="phone" name="phone" id="phone" placeholder='Téléphone' value={formFields.phone} onChange={e => setFormFields(prev => {return {...prev, phone: e.target.value}})}/>
          <input type="email" name="email" id="email" placeholder='Adresse mail' value={formFields.mail} onChange={e => setFormFields(prev => {return {...prev, mail: e.target.value}})}/>
        </div>
        <textarea name="content" id="content" placeholder="Votre message..." onChange={e => setFormFields(prev => {return {...prev, message: e.target.value}})} value={formFields.message}></textarea>
        <p style={{textAlign: 'right', marginRight: 10, fontSize: 20, marginBottom: 10}}>{message}</p>
        <div id="send_button">
          <div id="button" onClick={sendMessage}>Envoyer</div>
        </div>
      </div>
    </section>

    <Footer/>

    <Navbar />
  </>
  )
}



export default Homepage;