import React, {useEffect, useState} from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";

import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const useQuery = () => new URLSearchParams(useLocation().search);


export default function RecetteWrite(props) {
  const [authorized, setAuthorized] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("/api/check_access")
      .then(res => {
        if (res.ok) {
          setAuthorized(true);
        } else {
          navigate("/");
        }
      })
  }, [props.history])

  const [addIngredient, setAddIngredient] = useState("");
  const [addStep, setAddStep] = useState("");
  const [formField, setFormField] = useState({
    title: "",
    image: null,
    ingredients: [],
    steps: []
  })


  const handlePostRecette = () => {
    if (formField.title && formField.image && formField.ingredients && formField.steps) {
      fetch('/api/add-recette', {method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify({
        title: formField.title,
        ingredients: formField.ingredients,
        steps: formField.steps
      })})
        .then(res => {
          if (res.ok) {
            res.json()
              .then(data => {
                const formData = new FormData();
                formData.append('image', formField.image, "image");

                axios.post(`/api/add-recette-image?id=${data[0].id}`, formData)
                  .then(res => {
                    if (res.status === 200) {
                      navigate("/recette");
                    } else {
                      alert("Erreur, veuillez réessayer.");
                    }
                  })
              })
          } else {
            alert("Une erreur est survenue.");
          }
        })
    }
  }



  if (authorized) {
    return <>
      <h2 id="h2_recette">{formField.title ? formField.title : "Nouvelle recette"}</h2>
      <div style={{display: "flex", justifyContent: "center"}}>
        <input type="text" id="recette_title_input" placeholder="Nom de la recette" onChange={e => setFormField(prev => {return {...prev, title: e.target.value}})} />
      </div>
      
      
      <div id="write-recette">
        {formField.image && <img src={URL.createObjectURL(formField.image)} alt="recette illustration" id="selected-image"/>}
        <input style={{display: "none"}} id="input_image" type="file" accept=".png, .jpg, .jpeg" onChange={e => setFormField(prev => {return {...prev, image: e.target.files[0]}})} />
        <label htmlFor="input_image" id="recette-image_button">Choisir une image</label>

        <h3>Ingrédients :</h3>
        <ul id="ingredient_list">
          {formField.ingredients && formField.ingredients.map((i, index) => {
            return <li key={index}>- <input type="text" value={i} onChange={e => setFormField(prev => {
              let list = prev.ingredients;
              list[index] = e.target.value;

              return {...prev, ingredients: list};
            })}/></li>
          })}
        </ul>
        <form onSubmit={e => {e.preventDefault(); setFormField(prev => {return {...prev, ingredients: [...prev.ingredients, addIngredient]}}); setAddIngredient("")}}>
          <input type="text" id="input_ingredient" placeholder="Ajouter un ingrédient" value={addIngredient} onChange={e => setAddIngredient(e.target.value)} />
          <button type="submit">+</button>
        </form>


        <h3>Réalisation :</h3>
        {formField.steps && formField.steps.map((step, index) => {
          return <div className="recette_step" key={index}>
            <h4>Étape {index+1}</h4>
            <textarea value={step} onChange={e => setFormField(prev => {
              let list = prev.steps;
              list[index] = e.target.value;

              return {...prev, steps: list};
            })}></textarea>
          </div>
        })}
        <form onSubmit={e => {e.preventDefault(); setFormField(prev => {return {...prev, steps: [...prev.steps, addStep]}}); setAddStep("")}}>
          <textarea type="text" id="input_step" placeholder="Ajouter une étape" value={addStep} onChange={e => setAddStep(e.target.value)} />
          <button type="submit">+</button>
        </form>
        <div id="add-recette-button">
          <div onClick={handlePostRecette}>Ajouter la recette</div>
        </div>
      </div>

      



      <Footer />
      <Navbar color="yellow" />
    </>
  } else {
    return <div />
  }
}



export function RecetteEdit(props) {
  const navigate = useNavigate();
  
  const data = {
    id: useQuery().get("id"),
    title: useQuery().get("title"),
  };
  const [authorized, setAuthorized] = useState(false);
  const [formField, setFormField] = useState({
    title: "",
    image: null,
    ingredients: [],
    steps: []
  })

  useEffect(() => {
    fetch("/api/check_access")
      .then(res => {
        if (res.ok) {
          fetch(`/api/get-recette-details?id=${data.id}&title=${data.title}`)
            .then(res => {
              if (res.ok) {
                res.json()
                  .then(d => {
                    setFormField({title: d.title, image: d.image_url, steps: d.steps, ingredients: d.ingredients})
                    setAuthorized(true);
                  })
                
              } else {  
                navigate('/recette')
              }
            })
        } else {
          navigate("/");
        }
      })
  }, [props.history, data.title, data.id])

  const [addIngredient, setAddIngredient] = useState("");
  const [addStep, setAddStep] = useState("");


  const handleEditRecette = () => {
    if (formField.title && formField.image && formField.ingredients && formField.steps) {
      fetch('/api/edit-recette', {method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify({
        title: formField.title,
        ingredients: formField.ingredients,
        steps: formField.steps,
        id: data.id
      })})
        .then(res => {
          if (res.ok) {
            navigate("/recette")
          } else {
            alert("Une erreur est survenue.");
          }
        })
    }
  }



  if (authorized) {
    return <>
      <h2 id="h2_recette">{formField.title ? formField.title : "Nouvelle recette"}</h2>
      <div style={{display: "flex", justifyContent: "center"}}>
        <input type="text" id="recette_title_input" placeholder="Nom de la recette" onChange={e => setFormField(prev => {return {...prev, title: e.target.value}})} />
      </div>
      
      
      <div id="write-recette">
        {formField.image && <img src={formField.image} alt="recette illustration" id="selected-image"/>}

        <h3>Ingrédients :</h3>
        <ul id="ingredient_list">
          {formField.ingredients && formField.ingredients.map((i, index) => {
            return <li key={index}>- <input type="text" value={i.content} onChange={e => setFormField(prev => {
              let list = prev.ingredients;
              list[index].content = e.target.value;

              return {...prev, ingredients: list};
            })}/></li>
          })}
        </ul>
        <form onSubmit={e => {e.preventDefault(); setFormField(prev => {return {...prev, ingredients: [...prev.ingredients, {content: addIngredient, id: -1}]}}); setAddIngredient("")}}>
          <input type="text" id="input_ingredient" placeholder="Ajouter un ingrédient" value={addIngredient} onChange={e => setAddIngredient(e.target.value)} />
          <button type="submit">+</button>
        </form>


        <h3>Réalisation :</h3>
        {formField.steps && formField.steps.map((step, index) => {
          return <div className="recette_step" key={index}>
            <h4>Étape {index+1}</h4>
            <textarea value={step.content} onChange={e => setFormField(prev => {
              let list = prev.steps;
              list[index].content = e.target.value;

              return {...prev, steps: list};
            })}></textarea>
          </div>
        })}
        <form onSubmit={e => {e.preventDefault(); setFormField(prev => {return {...prev, steps: [...prev.steps, {content: addStep, id: -1}]}}); setAddStep("")}}>
          <textarea type="text" id="input_step" placeholder="Ajouter une étape" value={addStep} onChange={e => setAddStep(e.target.value)} />
          <button type="submit">+</button>
        </form>
        <div id="add-recette-button">
          <div onClick={handleEditRecette}>Editer la recette</div>
        </div>
      </div>

      



      <Footer />
      <Navbar color="yellow" />
    </>
  } else {
    return <div />
  }
}